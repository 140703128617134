"use client";

import Link from "next/link";
import { ArrowDownNarrowWide } from "lucide-react";
import { GameCard } from "@/components/game-card";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectIcon,
  SelectValue,
} from "@/components/select";
import { Layout } from "@/components/layout";
import { gameplayOptions } from "@/mocks/options";
import { SearchForm, useSearchForm } from "../../components/search-form";

function noop(): string {
  return "";
}

export default function Page(): JSX.Element {
  const form = useSearchForm();

  return (
    <Layout>
      <Layout.Header />
      <Layout.Body>
        <Layout.Left>
          <Layout.LeftAd>
            <div className="flex h-[600px] items-center justify-center rounded-md bg-[#fff] font-medium uppercase text-background">
              Ad
            </div>
          </Layout.LeftAd>
          <Layout.LeftSidebar>
            <Layout.ActivitySidebar>
              <Layout.ActivitySidebar.ActivityList>
                {Array.from({ length: 24 }).map((_, i) => {
                  return (
                    <Layout.ActivitySidebar.ActivityList.Item
                      key={i}
                      href={`/activities/${i}`}
                    />
                  );
                })}
              </Layout.ActivitySidebar.ActivityList>
            </Layout.ActivitySidebar>
          </Layout.LeftSidebar>
        </Layout.Left>
        <Layout.Middle>
          <div className="grid grid-rows-[auto_1fr] gap-2 overflow-hidden rounded-md bg-gray-900 p-2">
            <SearchForm
              {...form}
              onSubmit={() => {
                noop();
              }}
            >
              <SearchForm.Collapsible>
                <SearchForm.Header>
                  <SearchForm.HeaderLeft>
                    <SearchForm.Select1
                      options={[
                        { label: "Option 1", value: "option_1" },
                        { label: "Option 2", value: "option_2" },
                        { label: "Option 3", value: "option_3" },
                      ]}
                    />
                    <SearchForm.GameplayModeSelect options={gameplayOptions} />
                    <SearchForm.Select3
                      options={[
                        { label: "Option 1", value: "option_1" },
                        { label: "Option 2", value: "option_2" },
                        { label: "Option 3", value: "option_3" },
                      ]}
                    />
                  </SearchForm.HeaderLeft>
                  <SearchForm.HeaderRight>
                    <SearchForm.ClearFilters />
                    <SearchForm.Collapsible.Trigger />
                  </SearchForm.HeaderRight>
                </SearchForm.Header>

                <SearchForm.SearchInput />

                <SearchForm.Collapsible.Content>
                  <SearchForm.FiltersContainer>
                    <SearchForm.GeneralFilters>
                      <SearchForm.GeneralFilters.ItemCategorySelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />
                      <SearchForm.GeneralFilters.ItemRaritySelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />

                      <SearchForm.GeneralFilters.SocketsSelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />

                      <SearchForm.GeneralFilters.ClassSelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />

                      <SearchForm.GeneralFilters.AuctionTypeSelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />

                      <SearchForm.GeneralFilters.ListPeriodSelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />
                      <SearchForm.GeneralFilters.PriceRangeInput />
                      <SearchForm.GeneralFilters.ItemPowerRangeInput />
                      <SearchForm.GeneralFilters.LevelRequirementRangeInput />
                    </SearchForm.GeneralFilters>
                    <SearchForm.StatFilters>
                      {({ statFilters, removeStat }) => {
                        return (
                          <SearchForm.StatFilters.Content>
                            {statFilters.map((statFilter, statIndex) => {
                              return (
                                <SearchForm.StatFilters.StatFilter
                                  affixOptions={[
                                    {
                                      label: "Affix 1",
                                      value: "affix_1",
                                    },
                                    {
                                      label: "Affix 2",
                                      value: "affix_2",
                                    },
                                    {
                                      label: "Affix 3",
                                      value: "affix_3",
                                    },
                                    {
                                      label: "Affix 4",
                                      value: "affix_4",
                                    },
                                    {
                                      label: "Affix 5",
                                      value: "affix_5",
                                    },
                                    {
                                      label: "Affix 6",
                                      value: "affix_6",
                                    },
                                    {
                                      label: "Affix 7",
                                      value: "affix_7",
                                    },
                                    {
                                      label: "Affix 8",
                                      value: "affix_8",
                                    },
                                    {
                                      label: "Affix 9",
                                      value: "affix_9",
                                    },
                                    {
                                      label: "Affix 10",
                                      value: "affix_10",
                                    },
                                  ]}
                                  key={statFilter.id}
                                  removeStat={removeStat}
                                  statIndex={statIndex}
                                >
                                  {({ affixes }) => {
                                    return (
                                      <>
                                        {affixes.map((affix, affixIndex) => {
                                          return (
                                            <SearchForm.StatFilters.StatFilter.Affix
                                              affixIndex={affixIndex}
                                              affixTitle={affix.title}
                                              key={affix.id}
                                              statIndex={statIndex}
                                            />
                                          );
                                        })}
                                      </>
                                    );
                                  }}
                                </SearchForm.StatFilters.StatFilter>
                              );
                            })}
                          </SearchForm.StatFilters.Content>
                        );
                      }}
                    </SearchForm.StatFilters>
                  </SearchForm.FiltersContainer>
                </SearchForm.Collapsible.Content>
              </SearchForm.Collapsible>
            </SearchForm>

            <div className="flex flex-col gap-4">
              <div className="relative flex h-10 items-center justify-center justify-items-center bg-gray-800">
                <h3 className="font-diablo">
                  Showing 100 Results(100000 Matched)
                </h3>
                <div className="absolute bottom-0 right-0 top-0 w-40">
                  <Select>
                    <SelectTrigger
                      className="h-10 text-base"
                      defaultValue="newest"
                    >
                      <SelectValue className="px-4" />
                      <SelectIcon asChild>
                        <ArrowDownNarrowWide className="h-5 w-5 opacity-50" />
                      </SelectIcon>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="newest">Newest</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="relative flex-1">
                <div className="grid grid-cols-3  justify-items-center gap-4">
                  {Array.from({ length: 16 }).map(() => {
                    return (
                      <Link
                        href="/activities/222"
                        key={Math.random()}
                        scroll={false}
                      >
                        <GameCard />
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Layout.Middle>
        <Layout.Right>
          <Layout.RightSidebar></Layout.RightSidebar>
          <Layout.RightAd>
            <div className="flex h-[600px] items-center justify-center rounded-md bg-[#fff] font-medium uppercase text-background">
              Ad
            </div>
          </Layout.RightAd>
        </Layout.Right>
      </Layout.Body>
    </Layout>
  );
}
